<div id="main">
    
    <div class="title-section">
        <span style="font-weight: 600;">Portfólio Digital</span>
        <span style="font-size: 14px;">Alguns dos projetos que realizamos para nossos clientes.</span>
    </div>

    <div id="portfolio-box">
        <div id="portfolio-box-itens" *ngFor="let project of projects">
            <img src={{project.image}} id="main-message-photo" alt="Projetos de BI e Sistemas">
            <div class="portfolio-box-itens-div">
                <span class="portfolio-box-itens-title">{{project.title}}</span>
                <span class="portfolio-box-itens-subtitle">{{project.type_project.title}}</span>
                <span>{{project.description}}</span>
            </div>  
        </div>
    </div>
</div>