import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';


@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.less']
})
export class PortfolioComponent implements OnInit {

  public projects: any = [];

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.reqPublicProjects()
  }

  serviceGetPublicProjects() {
    return this.http.get('https://api.up2value.com/project/portfolio', {
      headers: {'Authorization':'m4rc10jr'}
    });
  }

  reqPublicProjects() {
    this.serviceGetPublicProjects().subscribe(async data => {
      this.projects = data;
      this.projects.sort((a:any, b:any) => (a.project_id > b.project_id ? -1 : 1));
    })
  }
}
