<div id="main">
  <div id="main-message-div">
    <div id="main-message-text">
      <span id="main-message-text-title">Usamos tecnologia para te levar ao topo.</span>
      <span>Desenvolvemos soluções inteligentes para seu negócio.</span>
      <a target=”_blank” href="https://wa.me/5534999073154?text=Olá,%20Gostaria%20de%20falar%20sobre%20meu%20projeto.">
        <button type="button" class="btn btn-primary button">Entre em contato</button>
      </a>
    </div>
    <div id="main-message-photo-div">
      <img src="../assets/image-carousel.jpg" id="main-message-photo" alt="Projetos de BI">
    </div>
  </div>

  <div id="skills-div">
    <div class="title-section">
      <span>Nossa Especialidade</span>
    </div>
    
    <div id="skills-box">
        <div class="skills-box-itens">
          <i class="bi bi-graph-up-arrow skills-box-itens-icons"></i>
          <span class="skills-box-itens-title">Soluções de BI</span>
          <span>Dashboards e Relatórios automatizados para análise do seu negócio.</span>
        </div>
        <div class="skills-box-itens">
          <i class="bi bi-window-sidebar skills-box-itens-icons"></i>
          <span class="skills-box-itens-title">Apps e Websites</span>
          <span>Aplicações Web e Mobile para sistematizar os dados mais importantes do seu negócio.</span>
        </div>
        <div class="skills-box-itens">
          <i class="bi bi-card-checklist skills-box-itens-icons"></i>
          <span class="skills-box-itens-title">Consultoria em Ciência de Dados</span>
          <span>Entendemos o seu negócio e te indicamos as melhores práticas e ferramentas de tecnologia.</span>
        </div>
    </div>
    <span (click)='redirectPath("portfolio")' style="cursor: pointer; font-weight: 500; text-decoration: underline; font-size: 14px;">Clique para ver nosso portfólio.</span>
  </div>
  
  <div id="how-work-div">
    <div class="title-section">
      <span>Como nós trabalhamos</span>
    </div>
    <div id="how-work-box">
      <img src="../assets/image-working.jpg" id="how-work-box-image" alt="Trabalhando com Sistemas e Dashboards BI">
      <div id="how-work-box-text">
        <ul class="how-work-box-text-itens">
          <li><i class="bi bi-check-lg how-work-box-icon"></i>Entendemos o seu negócio e a sua necessidade.</li>
          <li><i class="bi bi-check-lg how-work-box-icon"></i>Elaboramos propostas detalhadas e padronizadas visando estabelecer um preço justo.</li>
          <li><i class="bi bi-check-lg how-work-box-icon"></i>Acompanhamos o desenvolvimento das tarefas via sistema de controle de tarefas e prazos.</li>
          <li><i class="bi bi-check-lg how-work-box-icon"></i>Nos reunimos periódicamente para avaliar os produtos desenvolvidos.</li>
        </ul>
      </div>
    </div>
    <a target=”_blank” href="https://wa.me/5534999073154?text=Olá,%20Gostaria%20de%20falar%20sobre%20meu%20projeto.">
      <button type="button" class="btn btn-primary button">Solicite um Orçamento</button>
    </a>
  </div>
  
  <a target=”_blank” href="https://wa.me/5534999073154?text=Olá,%20Gostaria%20de%20falar%20sobre%20meu%20projeto.">
    <img class="whatsapp-icon" src="../assets/whatsapp-icon.png">
  </a>
</div>